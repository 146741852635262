<template>
    <div >
        <div v-show="isShow">
        <van-nav-bar
            title="客户详情"
            left-arrow
            @click-left="onClickLeft"
        />


        <div  class="schemeDiv">
            <div class="lineDiv">
                    <div class="line"></div>
                    <span>用电方案</span>
             </div>
              
             <div class="scheduleControl">{{data.scheduleControl}}</div>
        </div>
    <form ref="saveForm"  @submit.prevent="submit">
        <div class="basicDiv">
                <div class="lineDiv">
                    <div class="line"></div>
                    <span>申请信息</span>
                </div>
                <div class="smallLineDiv">
                    <p>{{enName}}</p>
                    <input  type="text" v-model=data.distributorName name="distributorName" readonly>
                </div>
                <div class="smallLineDiv">
                    <p>{{enPhone}}</p>
                    <input  type="text" v-model=data.distributorPhone name="distributorPhone" readonly>
                </div>
                <div class="smallLineDiv">
                    <p>{{usName}}</p>
                    <input type="text"  v-model=data.salesman name="salesman" readonly>
                </div>
                <div class="smallLineDiv">
                    <p>{{usPhone}}</p>
                    <input type="text"  v-model=data.salesmanPhone name="salesmanPhone" readonly>
                </div>
                <div class="smallLineDiv">
                    <p>公司名称</p>
                    <input type="text" placeholder="请输入" name="name" v-model=data.name>
                </div>
                <div class="smallLineDiv">
                    <p>公司地址</p>
                    <input type="text" placeholder="请输入" name="address" v-model="data.address">
                </div>
                <div class="smallLineDiv">
                    <p>联系人姓名</p>
                    <input type="text" placeholder="请输入" name="customerName" v-model="data.customerName">
                </div>
                <div class="smallLineDiv">
                    <p>联系人手机号</p>
                    <input type="text" placeholder="请输入" name="liaisonMobile" v-model="data.liaisonMobile">
                </div>
                <div class="smallLineDiv">
                    <p>用户形态</p>
                    <select name="customerType" v-model="data.customerType">
                        <option v-for="(item,index) in user_status" :key="index" :value="item.val">{{item.label}}</option>
                    </select>
                </div>
                <div class="smallLineDiv">
                    <p>电表户号</p>
                    <input type="text" placeholder="请输入" name="meterNo" v-model="data.meterNo">
                </div>
                <!-- <button class="addMyMessage"  type="submit" v-if="isShowSave">保存</button> -->
        </div>
                
        <div class="titleLineDiv" @click="toUploader">
            <div>
                三表讯息
            </div>
            <img src="../../assets/image/loder/right.png" alt="">
        </div>
        <div class="titleLineDiv" @click="electricityProject">
            <div>
                
                {{( this.data.scenarioOptions == 1 ? '折扣讯息':'储能讯息')}}
            </div>
            <img src="../../assets/image/loder/right.png" alt="">
        </div>
        <div class="titleLineDiv" @click="measureConclusion"  v-if="isShowTest">
            <div>
                测算结论
            </div>
            <img src="../../assets/image/loder/right.png" alt="">
        </div>
        <div class="titleLineDiv" @click="measureContract"  v-if="isShowContract">
            <div>
                合同
            </div>
            <img src="../../assets/image/loder/right.png" alt="">
        </div>
        <div class="titleLineDiv" style="margin-bottom:50px;" @click="toCheck" v-if="isShowCheck">
            <div>
                审核
            </div>
            <img src="../../assets/image/loder/right.png" alt="">
        </div >
            <button class="toPut" v-if="isShowSave" @click="sumitApply"   type="submit">提交</button>
       
        </form> 
    </div>

    

    <router-view :key="$route.fullPath"/>    
        
        
    </div>
</template>
<script>

import {getLabels,getApplyInfo,submit_apply} from '../../network/addMes'
//  toSave
export default{
        name:'BasicMessage',
        data(){
            return{
                isShow:true,
                isShowSave:true,//保存按钮
                orgName:sessionStorage.getItem('orgName'),
                saleName:sessionStorage.getItem('userName'),
                selectscheme:'',
                user_status:[],//用户形态
                isShowCheck:false,//是否有审核按钮,
                isShowTest:false,//测算结论
                data:{
                },
                selectUser:[],
                id:'',//案件id
                progress:'',//进度 新建=0
                isShowContract:false, //是否有合同

                enName:sessionStorage.getItem('enName'),
                enPhone:sessionStorage.getItem('enPhone'),
                usName:sessionStorage.getItem('usName'),
                usPhone:sessionStorage.getItem('usPhone'),
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back();
            },
            submit(e){   
                let request = {};
                const formData = new FormData(e.target);
                for (let [key, value] of formData) {
                    request[key] = value
                    // if(value == ''){
                    //     this.$toast.fail('请填写完整再提交');
                    //     return 
                    // }
                 }
                 request['id'] = this.id;
                 let userId = sessionStorage.getItem('userId')
                 if(userId){
                    request['userId'] = userId;
                 }
                 request['consumerId'] = this.data.consumerId;
                 this.$toast.loading({
                        message: '努力提交中...',
                        forbidClick: false,
                        duration:0
                     });
                //  toSave(request).then((res)=>{
                //         this.$toast.clear();
                //         if(res.success){
                //             sessionStorage.setItem('assessId',res.data)
                //             this.$toast.success(res.msg);
                //         }else{
                //             this.$toast.fail(res.msg);
                //         }
                //  })
                
                submit_apply(request).then((res)=>{  
                    this.$toast.clear();
                    if(res.success){
                        this.$toast.success(res.msg);
                      
                            this.$router.replace({
                                path: "basicMessage/customerSumit",
                                query:{
                                    plan: this.data.scenarioOptions
                                }
                            });
                         
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })
            },
            toUploader(){//三表讯息
                let id  = this.id;
                this.$router.push({
                    path: "basicMessage/uploderList",
                    query:{
                        id
                    }
                });
            
            },
            electricityProject(){//折扣讯息
                let id  = this.id;
                let plan = this.data.scenarioOptions;
                if(plan == 1){
                    plan = 'discount_scheme'
                }else if(plan == 2){
                    plan ='storage'
                }
                this.$router.push({
                    path: "basicMessage/uploderElectricityPlan",
                    query:{
                        plan,
                        id
                    }
                });
            },
            measureConclusion(){//测算结论
                let id  = this.id;
                this.$router.push({
                    path: "basicMessage/measureConclusion",
                    query:{
                        id
                    }
                });
            },
            measureContract(){//合同
                let id  = this.id;
                this.$router.push({
                    path: "basicMessage/measureContract",
                    query:{
                        id
                    }
                });
            },
            toCheck(){//审核
                let id  = this.id;
                let progress = this.$route.query.progress;
                this.$router.push({
                    path: "basicMessage/uploderCheck",
                    query:{
                        id,
                        progress
                    }
                });
            },
            distributorChange(){
                     let tabVal = this.selectDistributor;
                        getLabels(tabVal).then((res)=>{
                            if(res.success){
                                    this.distributor = res.data;
                            }
                        })
            },
            sumitApply(){  
                // let identity = sessionStorage.getItem('identity');
                // submit_apply(this.id).then((res)=>{
                //     if(res.success){
                //         if(identity == 'salesman'){
                //             this.$router.replace({
                //                 path: "customerList",
                //             });
                //         }else{
                //             this.$router.push({
                //                 path: "basicMessage/customerSumit",
                //             });
                //         }
                        
                //     }
                // })
               
            }
           
        },
       
        watch: {
            $route: {
                handler: function (val) {
                    if (val.path.indexOf("uploder") != -1 || val.path.indexOf("Conclusion") != -1 || val.path.indexOf("customer") != -1 || val.path.indexOf("Contract") != -1) {
                    this.isShow = false;
                    } else {
                    this.isShow = true;
                    }
                },
            },
        },
        mounted(){
           
           let tabVal2 = 'user_status';//用户形态
           getLabels(tabVal2).then((res)=>{
               if(res.success){
                    this.user_status = res.data;
                   //  this.selectUser = this.user_status[0].val;    
               }
           })
        },
        updated() {
            if (this.$route.path.indexOf("uploder") != -1  || this.$route.path.indexOf("Conclusion") != -1 || this.$route.path.indexOf("customer") != -1 || this.$route.path.indexOf("Contract") != -1) {
                this.isShow = false;
            }
        },
        created(){
            let identity = sessionStorage.getItem('identity');
            //总部=Headquarters 经销商=distributor 业务员=salesman 访客=customer
            //案件状态 0=新建 1=提交 2=审批中 4=已排单 3=驳回
            let progress = this.$route.query.progress;
            if(identity){
                this.isShowSave = false;
                if(identity != 'customer'){
                    this.isShowTest = true;
                    this.isShowCheck = true;
                }
                if(progress == '4'){
                    this.isShowContract = true;
                }
            }
             this.id  = this.$route.query.id;
             let userId = sessionStorage.getItem('userId')
             if(this.id){
                getApplyInfo(this.id,userId).then((res)=>{
                    if(res.success){
                       this.data = res.data
                    }
                });
             }
        }
        
       
    }
</script>
<style scoped>
.labelText{
    
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.scheduleControl{
    padding-right: 9%;
}
.schemeDiv{
    background: #ffffff;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}
.changeScheme{
    margin-top: 10px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-around;
}
.basicDiv{
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.lineDiv{
    display: flex;
    align-items: center;
}
.smallLineDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.smallLineDiv input{
    text-align: right;
}
.smallLineDiv p{
  line-height: 80px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
}
.line{
    width: 10px;
    height: 34px;
    background: #06B2B2;
    border-radius: 5px;
    margin: 32px 37px 26px 26px;
    display: inline-block;
}
.lineDiv span{
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}
.smallLineDiv{
    width: 82%;
    margin: auto;
    border-top: solid 2px #F4F4F5;
}
.contactDiv{
    width: 100%;
    background: #ECF6F6;
    line-height: 50px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #06B2B2;
    padding-left: 25px;
}
.addMyMessage{
    width: 82%;
    line-height: 70px;
    border: 2px solid #06B2B2;
    border-radius: 10px;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #06B2B2;
    text-align: center;
    margin: 20px auto;
    background: #ffffff;
}
.titleLineDiv{
    margin-top: 10px;
    height: 100px;
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleLineDiv div{
    margin-left:26px;
    height: 34px;
    border-left: 10px #06B2B2 solid;
    padding-left:37px;
    border-top-left-radius:5px;
    border-bottom-left-radius: 5px;
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}
.titleLineDiv img{
    width: 14px;
    margin-right: 67px;
}
.toPut{
    width: 88%;
    line-height: 80px;
    background: #06B2B2;
    border-radius: 40px;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin: 75px auto;
    text-align: center;
    display: block;
}
</style>